var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.userAccess.canCreate)?_c('v-btn',{attrs:{"color":"primary","elevation":"1"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$_strings.vaccine.ADD_TEXT)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"3"}},[_c('v-text-field',{staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.vaccine.SEARCHNO},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.search}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.isLoadingList,"headers":_vm.headers,"items":_vm.items,"item-key":"id","server-items-length":_vm.vaccineListTotalEntry,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"mt-3 pa-0",staticStyle:{"width":"14rem"},attrs:{"outlined":"","dense":"","items":_vm.itemStatus,"item-value":"value","item-text":"key","disabled":!_vm.userAccess.canUpdate,"loading":item.isLoading},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"change":function($event){return _vm.onChangeIsActive(item, $event)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.actionColumn",fn:function(ref){
var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function (){ return _vm.editData(item); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e(),(_vm.userAccess.canDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":"","color":"red"},on:{"click":[function (){ return _vm.deleteData(item); },function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]):_vm._e()]}}],null,true)})],1)],1),_c('v-row',[_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('span',{staticClass:"headtitle-email"},[_vm._v("Masukan Nama Vaksin")]),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","color":"black","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-subheader',[_vm._v("Vaksin")])],1),_c('v-col',{staticClass:"mr-4",attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":_vm.rules.vaccineRule,"clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}})],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2 ml-auto",attrs:{"outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Batal ")]),_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"primary","disabled":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }